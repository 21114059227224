<template>
    <BaseScene 
        name="entrance" 
        :background="ResourceManager.Get('scenes.entrance')" 
        @leave="OnLeaveScene" 
        @enter="OnEnterScene" 
        @firstLoad="OnEnterScene"
    >
        <SceneTrigger 
            scene="porch-main" 
            x="-16.5" 
            y="9.5" 
            :sound="ResourceManager.Get('shorts.fanfare')"
        />
        <!-- <SceneTrigger 
            scene="porch-main" 
            x="-16.5" 
            y="9.5" 
            :sound="ResourceManager.Get('shorts.fanfare')"
        /> -->

        <URLTrigger 
            url="https://theaterdeslachens.de/spielplan" 
            x="-28" 
            y="6" 
            :sound="ResourceManager.Get('laughs.arek.5')"
        /> 
        
        <VideoTrigger 
            x="-42.8" 
            y="-13" 
            url="qmnk6THYtvU" 
            :sound="ResourceManager.Get('laughs.torsten.5')" 
            :delay="500"
        />

        <VideoTrigger 
            x="-16.8" 
            y="-4.5" 
            url="Vi4fznGD6bI" 
            :sound="ResourceManager.Get('laughs.nicole.8')"
        />

        <div class="bird-container bird-container--one">
            <div class="bird bird--one"></div>
        </div>
        
        <div class="bird-container bird-container--two">
            <div class="bird bird--two"></div>
        </div>
        
        <div class="bird-container bird-container--three">
            <div class="bird bird--three"></div>
        </div>
        
        <div class="bird-container bird-container--four">
            <div class="bird bird--four"></div>
        </div>    

        <img class="poster poster-1" :src="ResourceManager.Get('posters.1')">
        <URLTrigger 
            :url="cms.get('entrance').get('poster_1_link')"
            x="6.5" 
            y="8" 
            :sound="ResourceManager.Get('laughs.gundula.3')"
        /> 

        <img class="poster poster-2" :src="ResourceManager.Get('posters.2')">
        <URLTrigger 
            :url="cms.get('entrance').get('poster_2_link')" 
            x="23.6" 
            y="8" 
            :sound="ResourceManager.Get('laughs.torsten.2')"
        /> 
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import URLTrigger from '../triggers/URLTrigger.vue';
import VideoTrigger from '../triggers/VideoTrigger.vue';
import { Howl } from 'howler';
import ResourceManager from "@/classes/resource-manager";

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
        SceneTrigger,
        URLTrigger,
        VideoTrigger
    },
    data() {
        return {
            ResourceManager,
            bgm: null
        };
    },
    mounted() {
        this.OnEnterScene();
    },
    methods: {
        OnEnterScene() {
            setTimeout(() => {
                new Howl({
                    src: [ResourceManager.Get('shorts.ship-horn')],
                    volume: 1
                }).play();
            }, 2000);

            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.entrance')],
                    loop: true,
                    volume: 0.3
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.stop();
        }
    }
}
</script>

<style lang="scss">
.scene-entrance {
    .poster {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include width-ar(15.63);
        box-shadow: -3px -3px 20px black;

        &.poster-1 {
            @include xy-ar(6.5, -3.1);
        }

        &.poster-2 {
            @include xy-ar(23.5, -3.1);
        }
    }

    .bird {
        background-image: url('../../assets/images/bird-sheet.svg');
        background-size: auto 100%;
        width: 88px;
        height: 125px;
        will-change: background-position;
        user-select: none;
        
        animation-name: fly-cycle;
        animation-timing-function: steps(10);
        animation-iteration-count: infinite;        

        &--one {
            animation-duration: 1s;
            animation-delay: -0.5s;		
        }
        
        &--two {
            animation-duration: 0.9s;
            animation-delay: -0.75s;
        }
        
        &--three {
            animation-duration: 1.25s;
            animation-delay: -0.25s;
        }
        
        &--four {
            animation-duration: 1.1s;
            animation-delay: -0.5s;
        }
    }

    .bird-container {
        position: absolute;
        top: 0%;
        left: -10%;
        transform: scale(0) translateX(-10vw);
        will-change: transform;
        user-select: none;
        
        animation-name: fly-right-one;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        
        &--one {
            animation-duration: 15s;
            animation-delay: 0;
        }
        
        &--two {
            animation-duration: 16s;
            animation-delay: 1s;
        }
        
        &--three {
            animation-duration: 14.6s;
            animation-delay: 9.5s;
        }
        
        &--four {
            animation-duration: 16s;
            animation-delay: 10.25s;
        }
        
    }

    @keyframes fly-cycle {        
        100% {
            background-position: -900px 0;
        }        
    }

    @keyframes fly-right-one {        
        0% {
            transform: scale(0.3) translateX(-10vw);
        }
        
        10% {
            transform: translateY(2vh) translateX(10vw) scale(0.4);
        }
        
        20% {
            transform: translateY(0vh) translateX(30vw) scale(0.5);
        }
        
        30% {
            transform: translateY(4vh) translateX(50vw) scale(0.6);
        }
        
        40% {
            transform: translateY(2vh) translateX(70vw) scale(0.6);
        }
        
        50% {
            transform: translateY(0vh) translateX(90vw) scale(0.6);
        }
        
        60% {
            transform: translateY(0vh) translateX(110vw) scale(0.6);
        }
        
        100% {
            transform: translateY(0vh) translateX(110vw) scale(0.6);
        }
        
    }

    @keyframes fly-right-two {        
        0% {
            transform: translateY(-2vh) translateX(-10vw) scale(0.5);
        }
        
        10% {
            transform: translateY(0vh) translateX(10vw) scale(0.4);
        }
        
        20% {
            transform: translateY(-4vh) translateX(30vw) scale(0.6);
        }
        
        30% {
            transform: translateY(1vh) translateX(50vw) scale(0.45);
        }
        
        40% {
            transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
        }
        
        50% {
            transform: translateY(0vh) translateX(90vw) scale(0.45);
        }
        
        51% {
            transform: translateY(0vh) translateX(110vw) scale(0.45);
        }
        
        100% {
            transform: translateY(0vh) translateX(110vw) scale(0.45);
        }
    }
}
</style>